








import { Component, Vue } from 'vue-property-decorator'
import BezeButton from '@/components/common/BezeButton.vue'

@Component({
  components: {
    BezeButton
  }
})
export default class NotFound extends Vue {}
